import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from "./pages/homepage/HomePage";
import AboutPage from './pages/aboutpage/AboutPage';
// import VisionPage from './pages/visionpage/VisionPage';
import MapsPage from "./pages/mapspage/MapsPage";

function App({ scrollTop }) {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage scrollTop={scrollTop} lang="en" />} />
        <Route path='/bn' element={<HomePage scrollTop={scrollTop} lang="bn" />} />
        <Route path='/about' element={<AboutPage scrollTop={scrollTop} lang="en" />} />
        <Route path='/about/bn' element={<AboutPage scrollTop={scrollTop} lang="bn" />} />
        <Route path='/ledger' element={<AboutPage scrollTop={scrollTop} lang="en" section="ledger" />} />
        <Route path='/ledger/bn' element={<AboutPage scrollTop={scrollTop} lang="bn" section="ledger" />} />
        <Route path='/loan' element={<AboutPage scrollTop={scrollTop} lang="en" section="loan" />} />
        <Route path='/loan/bn' element={<AboutPage scrollTop={scrollTop} lang="bn" section="loan" />} />
        <Route path='/guidance' element={<AboutPage scrollTop={scrollTop} lang="en" section="guidance" />} />
        <Route path='/guidance/bn' element={<AboutPage scrollTop={scrollTop} lang="bn" section="guidance" />} />
        <Route path='/expert' element={<AboutPage scrollTop={scrollTop} lang="en" section="expert" />} />
        <Route path='/expert/bn' element={<AboutPage scrollTop={scrollTop} lang="bn" section="expert" />} />
        <Route path='/services' element={<AboutPage scrollTop={scrollTop} lang="en" section="services" />} />
        <Route path='/services/bn' element={<AboutPage scrollTop={scrollTop} lang="bn" section="services" />} />
        {/* <Route path='/vision' element={<VisionPage scrollTop={scrollTop} lang="en" />} />
        <Route path='/areas' element={<MapsPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
