import { useState, useEffect } from "react";
import TopBar from "../../components/topbar/TopBar";
import Footer from "../../components/footer/Footer";
import "./HomePage.css";

function HomePage({ scrollTop, lang }) {
  const [promo, setPromo] = useState("one"); if(!lang) lang = "BN";
  const [bangla, setBangla] = useState(lang.toLowerCase()==="bn");
  // console.log("lang:", lang);
  useEffect(() => {
    const interval = setInterval(() => {
      setPromo(promo => { // console.log("promo:", promo);
        switch(promo) {
          case "one": return "two";
          case "two": return "three";
          case "three": return "four";
          case "four": return "five";
          case "five": return "six";
          default: return "one";
        }
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="homepage" id="home">
      <TopBar scrollTop={scrollTop} bangla={bangla} />
      <div className={`banner ${promo}`}>
        <figure>
          <img className="one" src="/shop.jpg" alt="Shop" /> {/* /shop.jpg */}
          <img className="two" src="/img/agro_1.jpg" alt="Agro 1" />
          <img className="three" src="/img/agro_3.jpg" alt="Agro 3" />
          <img className="four" src="/img/agro_5.jpg" alt="Agro 5" />
          <img className="five" src="/products.jpg" alt="Cottage 3" />
          <img className="six" src="/img/fisheries_1.jpg" alt="Fisheries 1" />
        </figure>
        {/* <div className="overlay"></div> */}
      </div>

      <section className="biz">
        <h2>{bangla?"সব ব্যবসায়িক সল্যুশন এক প্ল্যাটফর্মে":"One Platform for All Business Solutions"}</h2>
        <img src="/orko_mobile1.jpg" alt="Orko Mobile" />
        <h4>{bangla?"অর্কো-র সার্ভিস সম্পর্কে আরো জানুন":"Learn More about Orko Services"}</h4>
        <div className="biz-services">
          <div className="service">
            <i className="fa-solid fa-book"></i><h5>{bangla?"কাঁচা খাতা":"Ledger"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-sack-dollar"></i><h5>{bangla?"ঋণের আবেদন":"Apply Loan"}</h5>
          </div>
          <div className="service">
            <i className="fa-regular fa-calendar-days"></i><h5>{bangla?"অ্যাপয়েন্টমেন্ট বুকিং":"Book Appointment"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-hands-holding"></i><h5>{bangla?"বীমা":"Insurance"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-gears"></i><h5>{bangla?"সাপ্লাইয়ার সংযোগ":"Supplier Connect"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-bullhorn"></i><h5>{bangla?"সংবাদ আপডেট":"News & Updates"}</h5>
          </div>
        </div>
      </section>

      <section className="download" id="download">
        <img className="mobile" src="/orko_mobile1.jpg" alt="Orko Mobile" />
        <h2>{bangla?"অর্কো অ্যাপ ডাউনলোড করুন":"Download Orko App"}</h2>
        <p>{bangla?"অর্কো অ্যাপ থেকে সহজেই একাউন্ট খুলুন":"Open your Account from the Orko App in a few simple steps"}</p>
        <img className="social google" src="/Google-Play-Icon.png" alt="Google-Play-Icon" />
        <img className="social apple" src="/Download_on_the_App_Store_Badge.svg.png" alt="app-store-apple" />
      </section>

      {/* <div className="divider-box" id="about"></div>
      <div className="about-head"><h1>About ORKO</h1></div>
      <div className="about">
        <p>
          Orko Bangladesh is a Management Consulting firm registered in August 31,
          2022 with an objective to <strong><em>support CMSMEs</em></strong> for <em>Business Development</em>, Brand Promotion,
          Digital Marketing, New Product Launch, Human Resources <em>recruitment & Training</em>, Retail
          Distribution channel set up, Project management, Franchising, <em>Fund raising</em> etc.
        </p>
      </div>
      <div className="about-foot"></div> */}

      {/* <div className="divider-box" id="vision"></div>
      <div className="vision-head"><h1>Vision &</h1><br /><h1>Values</h1></div>
      <div className="vision">
        <p>
          Orko is a digital platform that provides CMSME customers management
          guidance & financial solution from partner Banks & Financial Institutions
          thereby serving their financial and operational needs.
        </p>
        <div className="left">
          <h3><i className="fa-regular fa-eye"></i> Vision</h3>
          <p>The <em>most preferred organization for the development of progressive Micro
             Enterprises</em> to enhance wealth creation and social well-being of the nation.</p>
        </div>
        <div className="right">
          <h3><i className="fa-solid fa-scale-balanced"></i> Values</h3>
          <p>Our exceptional client service is deeply entwined in a <strong>
            <span>C</span><span>H</span><span>A</span><span>I</span><span>N</span> </strong>
             value system</p>
          <ul>
            <li><i className="fa-regular fa-handshake"></i> <strong><span>C</span>ollaboration</strong> - we work
             together to achieve collective and individual goals.</li>
            <li><i className="fa-solid fa-brain"></i> <strong><span>H</span>olistic perspective</strong> - we seek to
             continuously identify competitive advantage in all of our engagements through an outside-in
             and inside-out approach and convey a holistic perspective to our clients/ engagement partners
             to empower objective decision making based on rigorous data seeding & analysis</li>
            <li><i className="fa-solid fa-hand-point-right"></i> <strong><span>A</span>ccountability</strong> - We
             take responsibility for individual and collective actions</li>
            <li><i className="fa-brands fa-medium"></i> <strong><span>I</span>mpact</strong> - we are inspired
             to make a positive & lasting social impact!!!</li>
            <li><i className="fa-brands fa-pagelines"></i> <strong><span>N</span>urturing relationships</strong> - the way
             we treat each other in achieving our objectives is just as important as what we achieve</li>
          </ul>
        </div>
      </div>
      <div className="vision-foot"></div> */}

      {/* <div className="divider-box" id="services"></div>
      <div className="services-head"><h1><span>S</span>ervices</h1></div>
      <div className="services">
        <img src="/products.jpg" />
        <ul>
          <li><i className="fa-solid fa-person-chalkboard"></i> Expert opinion</li>
          <li><i className="fa-solid fa-magnifying-glass"></i> Market Research</li>
          <li><i className="fa-solid fa-plane-departure"></i> Product Launching</li>
          <li><i className="fa-solid fa-bullhorn"></i> Brand Promotion</li>
          <li><i className="fa-solid fa-users"></i> Recruitment</li>
          <li><i className="fa-solid fa-graduation-cap"></i> Training</li>
          <li><i className="fa-solid fa-computer"></i> IT Solutions</li>
          <li><i className="fa-solid fa-sack-dollar"></i> Fund Raising</li>
        </ul>
      </div>
      <div className="services-foot"></div> */}

      <Footer bangla={bangla} />
    </div>
  );
}

export default HomePage;
