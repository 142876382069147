import React, { useState, useEffect } from 'react';
import './TopBar.css';
let hoveringDiv = false, hoveringNav = false, j = -1;

// React component returns JSX kind of markup
function TopBar({ scrollTop, bangla }) { // console.log("window.innerWidth: ", window.innerWidth);
  const [search, setSearch] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState([]);
  const [hovering, setHovering] = useState([]);
  // const [j, setJ] = useState(-1); //console.log("TopBar: j =", j, ", showDropdown =", showDropdown);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth>990);
  window.onresize = () => setIsDesktop(window.innerWidth>990);
  const height = isDesktop?"62px":"72px";
  function searchHandler() { // console.log("searchHandler:", search);
    setSearch(prevSearch => !prevSearch);
  } // console.log('TopBar: scrollTop -', scrollTop);
  const menuClickHandler = () => setMenuActive(prevActive => !prevActive);
  const mouseEnterHandler = function(i) { // console.log("mouseEnterHandler: i =", i);
    if(i>0) hoveringNav = true;
    const newValues = []; newValues[i] = true; setHovering(newValues);
  }
  const mouseLeaveHandler = function(i) { // console.log("mouseLeaveHandler: i =", i);
    hoveringNav = false;
    setTimeout(() => { // console.log("hoveringDiv =", hoveringDiv);
      // if(!hoveringDiv) setHovering([]);
      if(!hoveringDiv && !hoveringNav) {
        setHovering(prevValues => { // console.log("i =", i, ", j =", j);
          const newValues = prevValues; //.map((v, indx) => (indx===i?v:false));
          newValues[i] = false;
          // console.log("i =", i, ", newValues[i] =", newValues[i]);
          return newValues;
        }); if(i>0) mouseEnterHandler(0);
      }
    }, 300);
  }
  // const subMenuHandler = function(i) { console.log("subMenuHandler: i =", i);
  //   console.log("showDropdown:", showDropdown);
  //   setShowDropdown(prevValues => {
  //     const newValues = prevValues.map(v => v);
  //     // console.log("newValues[i]:", newValues[i], ", !newValues[i]:", !newValues[i]);
  //     newValues[i] = !newValues[i]; return newValues;
  //   });
  // }
  const subMenuHandler = function(i, e) { // console.log("subMenuHandler: i =", i, ", j =", j);
    // console.log("subMenuHandler: i =", i, ", j =", j, ", showDropdown =", showDropdown);
    e.stopPropagation(); // console.log("e:", e);
    if (i>0) { j=i; setTimeout(() => {j=0}, 100); //setJ(i), setJ(0)
      setTimeout(() => { // console.log("i =", i); //setShowDropdown([]);
        setHovering([]); setShowDropdown(prevValues => { // console.log("i =", i, ", j =", j);
        // const newValues = prevValues.map(v => v);
        const newValues = prevValues.map((v, indx) => (indx===i?v:false));
        // console.log("newValues:", newValues);
        // console.log("newValues[i]:", newValues[i], ", !newValues[i]:", !newValues[i]);
        newValues[i] = !newValues[i]; //console.log("newValues:", newValues);
        return newValues;
      });}, 1);
    } else if (j===0) {
      setShowDropdown([]); j = -1; // console.log("Emptied showDropdown!"); setJ(-1)
    }
    return false;
  }
  const appClickHandler = () => { console.log("appClickHandler");
    window.location.href = "#download";
  }
  useEffect(() => { // console.log("useEffect");
    window.addEventListener("keyup", e => { // console.log("e.key:", e.key);
      if(e.key === "Escape") { setShowDropdown([]); setHovering([]); }
    });
  }, [setShowDropdown, setHovering]);
  return (
    <>
      {/* <nav className="topbar-top">
        <ul className="topbar-topmenu">
          <li className="topbar-topitem topbar-sm-hide"><a href="#">downloads</a></li>
          <li className="topbar-topitem"><a href="#">contact us</a></li>
          <li className="topbar-topitem">
            <a href="#" className="highlight">locations</a>
          </li>
          <li className="topbar-topitem topbar-sm-hide">
            <a href="#">complaint cell <img src="/call-icon-ani.gif" alt="complaint-cell" /></a>
          </li>
        </ul>
      </nav> */}

      {/* <div className="topbar-logo">
        <div className="topbar-logo-img"><img src="/logo.png" alt="Logo" /></div>
        <div className="topbar-logo-srch">
          <span onClick={searchHandler}>search <i className="fa-solid fa-magnifying-glass"></i></span>
        </div>
      </div> */}

      <div className="topbar-nav" style={{height: (isDesktop?"auto":"auto")
        , position: (menuActive && !isDesktop?"relative":"sticky")}}
        onClick={subMenuHandler.bind(this,0)}>
        {/* <div className="topbar-srch" style={{height: (search?height:"0px"),
          zIndex: (search?15:9), padding: (search?"10px 0":"0px")}}>
          <form className="topbar-srch-frm" action="#" method="GET">
            <div className="topbar-srch1">
              <input className="topbar-srch1-inp" type="text" name="keyword" placeholder="Tell us what you are looking for" />
            </div>
            <div className="topbar-srch2">
              <input className="topbar-srch2-btn" type="submit" value="Search" />
              <span className="topbar-srch2-cls" onClick={searchHandler}>Close</span>
            </div>
          </form>
        </div> */}
        <nav className="topbar-nav1" style={{backgroundColor: "rgb(226, 19, 110)"}}>
          <ul style={{display: (isDesktop||menuActive?"block":"none")}}>
            <li>
              <a href="/"> {/* #home */}
                {/* <i className="fa-solid fa-house-chimney"></i> */}
                {bangla?(<div style={{padding: "0 68px 0 9px", backgroundColor: "transparent",
                  height: "60.4px"}}><img src="/orko_4.png" alt="Logo" /><p style={{color: "#fff",
                  position: "absolute", top: "18px", left: "100px", fontSize: "135%",
                  fontWeight: 600}}>অর্কো</p></div>)
                :<img src="/orko_logo1.png" alt="Logo" />}
              </a>
            </li>
            {/* <li><a href="#vision">{bangla?"দৃষ্টি এবং মূল্যবোধ":"Vision & Values"}</a></li> */}
            {/* <li><a href="/vision">{bangla?"দৃষ্টি এবং মূল্যবোধ":"Vision & Values"}</a></li> */}

            {/* ------------------------------------------------------------------------------- */}

            <li><a href={"/about"+(bangla?"/bn":"")}>{bangla?"অর্কো পরিচিতি":"About ORKO"}</a></li>

            <li><a href={"/ledger"+(bangla?"/bn":"")}>{bangla?"লেজার রক্ষণাবেক্ষণ":"Ledger Maintenance"}</a></li>

            <li><a href={"/loan"+(bangla?"/bn":"")}>{bangla?"ঋণের আবেদন":"Loan Request"}</a></li>

            <li><a href={"/guidance"+(bangla?"/bn":"")} onMouseEnter={mouseEnterHandler.bind(this,0)}
              onMouseLeave={mouseLeaveHandler.bind(this,0)}>{bangla?"পরিচালন সহায়তা":"Management Guidance"}</a></li>

            {/* ------------------------------------------------------------------------------- */}

            {/* <li><a href={"/services"+(bangla?"/bn":"")} onMouseEnter={mouseEnterHandler.bind(this,0)}
              onMouseLeave={mouseLeaveHandler.bind(this,0)}>{bangla?"সেবা সমূহ":"Services"}</a></li> */}

            {/* ------------------------------------------------------------------------------- */}

            <li className={`${showDropdown[1]?"showDropdown":""}`}>
              <a href="/expert" onClick={subMenuHandler.bind(this,1)} onMouseEnter={mouseEnterHandler.bind(this,1)}
              onMouseLeave={mouseLeaveHandler.bind(this,1)}>{bangla?"বিশেষজ্ঞ মতামত":"Expert Opinion"}</a>
              <span onClick={subMenuHandler.bind(this,1)}>
                <i className="fa-solid fa-chevron-down"></i>
              </span>
              {/* <ul className="topbar-nav1-dropdown">
                <li><a href="#">Agiculture</a></li>
                <li><a href="#">Livestock</a></li>
                <li><a href="#">Cold Storage</a></li>
                <li><a href="#">Food Processing</a></li>
                <li><a href="#">Manufacturing</a></li>
                <li><a href="#">Pharmacy</a></li>
                <li><a href="#">Garments</a></li>
                <li><a href="#">Real Estate</a></li>
                <li><a href="#">Others</a></li>
              </ul> */}
              <div className="dropdown-box" style={{display:`${showDropdown[1]||hovering[1]?"block":"none"}`}}
               onMouseEnter={()=>{hoveringDiv=true;}} onMouseLeave={()=>{hoveringDiv=false;setTimeout(()=>{if(!hoveringNav)mouseEnterHandler(0)},300)}}>
                <div className="container">
                  <div></div>
                  <nav>
                    <ul className="menu">
                      <li><a href="#"><i className="fa-solid fa-tractor"></i>{bangla?"কৃষি":"Agriculture"}</a></li>
                      <li><a href="#"><i className="fa-solid fa-cow"></i>{bangla?"পশু পালন":"Livestock"}</a></li>
                      <li><a href="#"><i className="fa-solid fa-warehouse"></i>{bangla?"কোল্ড স্টোরেজ":"Cold Storage"}</a></li>
                      <li><a href="#"><i className="fa-solid fa-cake-candles"></i>{bangla?"খাবার প্রক্রিয়াজাতকরণ":"Food Processing"}</a></li>
                      <li><a href="#"><i className="fa-solid fa-industry"></i>{bangla?"ম্যানুফ্যাকচারিং":"Manufacturing"}</a></li>
                      <li><a href="#"><i className="fa-solid fa-prescription-bottle-medical"></i>{bangla?"ঔষধ শিল্প":"Pharmacy"}</a></li>
                      <li><a href="#"><i className="fa-solid fa-shirt"></i>{bangla?"পোশাক শিল্প":"Garments"}</a></li>
                      <li><a href="#"><i className="fa-solid fa-city"></i>{bangla?"আবাসন প্রকল্প":"Real Estate"}</a></li>
                      <li><a href="#">{bangla?"প্লাস্টিক":"Plastics"}</a></li>
                      <li><a href="#">{bangla?"অন্যান্য":"Others"}</a></li>
                    </ul>
                  </nav>
                  <div></div>
                </div>
              </div>
            </li>

            {/* ------------------------------------------------------------------------------- */}

            {/* <li className={`${showDropdown[2]?"showDropdown":""}`}>
              <a href="#" onClick={subMenuHandler.bind(this,2)} onMouseEnter={mouseEnterHandler.bind(this,2)}
              onMouseLeave={mouseLeaveHandler.bind(this,2)}>{`CMSME ${bangla?"সদস্য":"Members"}`}</a>
              <span onClick={subMenuHandler.bind(this,2)}>
                <i className="fa-solid fa-chevron-down"></i>
              </span> */}
              {/* <ul className="topbar-nav1-dropdown">
                <li><a href="#">Agricultural Farms</a></li>
                <li><a href="#">Grocery Shops</a></li>
                <li><a href="#">Pharmacies</a></li>
                <li><a href="#">Bakeries</a></li>
                <li><a href="#">Department Stores</a></li>
                <li><a href="#">Garment Manufacturers</a></li>
                <li><a href="#">Property Developers</a></li>
                <li><a href="#">Construction Companies</a></li>
                <li><a href="#">Jute Mills</a></li>
                <li><a href="#">Shoe Makers</a></li>
              </ul> */}
              {/* <div className="dropdown-box" style={{display:`${showDropdown[2]||hovering[2]?"block":"none"}`}}
               onMouseEnter={()=>{hoveringDiv=true}} onMouseLeave={()=>{hoveringDiv=false;setTimeout(()=>{if(!hoveringNav)mouseEnterHandler(0)},300)}}>
                <div className="container">
                  <div></div>
                  <nav>
                    <ul className="menu">
                      <li><a href="#">{bangla?"কৃষি খামার":"Agricultural Farms"}</a></li>
                      <li><a href="#">{bangla?"মুদি দোকান":"Grocery Shops"}</a></li>
                      <li><a href="#">{bangla?"ফার্মেসি":"Pharmacies"}</a></li>
                      <li><a href="#">{bangla?"বেকারি":"Bakeries"}</a></li>
                      <li><a href="#">{bangla?"ডিপার্টমেন্ট স্টোর":"Department Stores"}</a></li>
                      <li><a href="#">{bangla?"পোশাক প্রস্তুতকারক":"Garment Manufacturers"}</a></li>
                      <li><a href="#">{bangla?"ডেভেলপার কোম্পানি":"Property Developers"}</a></li>
                      <li><a href="#">{bangla?"কনস্ট্রাকশন কোম্পানি":"Construction Companies"}</a></li>
                      <li><a href="#"><i className="fa-solid fa-wind"></i>{bangla?"জুট মিল":"Jute Mills"}</a></li>
                    </ul>
                  </nav>
                  <div></div>
                </div>
              </div>
            </li> */}

            {/* ------------------------------------------------------------------------------- */}

            {/* <li><a href="#blog">{bangla?"ব্লগ":"Blog"}</a></li> */}

            {/* ------------------------------------------------------------------------------- */}

            <li className="lang">
              <a href="/" style={{fontWeight: (bangla?"":"bolder")}}>Eng</a> |
              <a href="/bn" style={{fontWeight: (bangla?"bolder":"")}}>বাংলা</a>
            </li>
            <li className="btn">
              <div></div><button onClick={appClickHandler}>
                {(bangla?"অর্কো অ্যাপ":"Orko App")}
              </button><div></div>
            </li>
            {/* <li className={`imp menu ${showDropdown[3]?"showDropdown":""}`}>
              <a href="#"><img src="logo1.png" /><div className="screen"></div></a>
              <span onClick={subMenuHandler.bind(this,3)}>
                <i className="fa-solid fa-chevron-down"></i>
              </span>
              <ul className="topbar-nav1-dropdown">
                <li><a href="#">Partners</a></li>
                <li><a href="#">Agents</a></li>
                <li><a href="#">Promotions</a></li>
              </ul>
            </li> */}
            {/* <li className={`imp logo${scrollTop<98?"":" stk"}`}></li> */}
          </ul>

          <span className={`topbar-nav1-mobile${menuActive?" active":""}`} onClick={menuClickHandler}>
            Menu <i className="fa-solid fa-chevron-down"></i>
          </span>
        </nav>
        <div className={"topbar-nav2 "+(scrollTop<98?"bc":"st")+"kimg"} style={{display: "none",
          flex: (scrollTop<98?6.5:10.5), height: (scrollTop<98?"51px":"75px")}}></div>
        <div className="topbar-nav3"
          style={{display: "none", marginTop: (scrollTop<98?0:"12px")}}></div>
        <div className="topbar-nav4" style={{height:
          (isDesktop?"60.4px":!(menuActive&&scrollTop>97)?"46.5px":"34.5px")}}></div>
      </div>

      {/* <br />
      <div className="topbar-tut">
        <div className="topbar-left">
          <i className="topbar-social-icon fa-brands fa-instagram"></i>
          <i className="topbar-social-icon fa-brands fa-facebook"></i>
          <i className="topbar-social-icon fa-brands fa-linkedin"></i>
          <i className="topbar-social-icon fa-brands fa-twitter"></i>
        </div>
        <div className="topbar-center">
          <ul className="topbar-list">
            <li className="topbar-list-item dropdown">
              <a className="dropdown-btn">HOME</a>
              <ul className="dropdown-content">
                <li><a>Link 1</a></li>
                <li><a>Link 2</a></li>
                <li><a>Link 3</a></li>
              </ul>
            </li>
            <li className="topbar-list-item dropdown"><a className="dropdown-btn">ABOUT</a></li>
            <li className="topbar-list-item">CONTACT</li>
            <li className="topbar-list-item">CREATE LISTING</li>
            <li className="topbar-list-item">LOGOUT</li>
          </ul>
        </div>
        <div className="topbar-right">
          <img className="topbar-profile-pic" src="https://images.pexels.com/photos/2182970/pexels-photo-2182970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <i className="topbar-search fa-solid fa-magnifying-glass"></i>
        </div>
      </div> */}
    </>
  );
}

export default TopBar;
