import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
function RootApp() {
  const [scrollTop, setScrollTop] = useState(0);
  function scrollHandler(event) { // console.log('scrollHandler');
    // console.log('scrollTop: ', event.currentTarget.scrollTop);
    // console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    setScrollTop(event.currentTarget.scrollTop);
  }
  return (
    <div style={{height: '100vh', overflow: 'auto'}} onScroll={scrollHandler}>
      <App scrollTop={scrollTop} />
    </div>
  );
}
root.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>
);
