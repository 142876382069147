import { useState, useEffect, useRef } from "react";
import TopBar from "../../components/topbar/TopBar";
import Footer from "../../components/footer/Footer";
import "./AboutPage.css";

function AboutPage({ scrollTop, lang, section }) {
  const [bangla, setBangla] = useState(lang.toLowerCase()==="bn");
  const services = useRef(null); // console.log("AboutPage: section =", section);
  const expert = useRef(null), about = useRef(null);

  const scrollToSection = (elementRef) => { //console.log("elementRef:", elementRef);
    console.log("section:", section);
    //console.log("elementRef.current:", elementRef.current);
    if(section==="ledger") window.location.replace("#ledger");
    if(section==="loan") window.location.replace("#loan");
    if(section==="guidance") window.location.replace("#guidance");
    if(section==="expert") window.location.replace("#expert");
    if (elementRef.current) { // console.log("elementRef.current:", elementRef.current);
      // console.log("elementRef.current.offsetTop:", elementRef.current.offsetTop);
      if(section==="services") window.location.replace("#services");
      // setTimeout(()=>{ console.log("Scrolling To:", { top: elementRef.current.offsetTop, behavior: "smooth" });
      //   window.scrollTo({ top: elementRef.current.offsetTop, behavior: "smooth" });
      // }, 300);
    } // else console.log("Loading...");
  };

  return (
    <div className="aboutpage" id="about">
      <TopBar scrollTop={scrollTop} bangla={bangla} />
      {/* <div className="divider-box" id="about"></div> */}
      <div className="about-head" ref={about}><h1>About ORKO</h1></div>
      <div className="about" onLoad={()=>setTimeout(()=>scrollToSection(about), 50)}>
        {/* <p>
          Orko Bangladesh is a Management Consulting firm registered in August 31,
          2022 with an objective to <strong><em>support CMSMEs</em></strong> for <em>Business Development</em>, Brand Promotion,
          Digital Marketing, New Product Launch, Human Resources <em>recruitment & Training</em>, Retail
          Distribution channel set up, Project management, Franchising, <em>Fund raising</em> etc.
        </p> */}
        <p>
          ORKO is a digital platform for CMSME (Cottage, Micro, Small & Medium Enterprise) customers.
        </p><br />
        <p>
          Using Artificial Intelligence, ORKO creates a digital business profile and a credit score
          named '<strong>ORKO score</strong>' for each entrepreneur, which can be utilized by lenders to make
          loan decisions.
        </p><br />
        <p>The ORKO score is derived from a variety of sources, including:</p>
        <ul className="bullet">
          <li>Daily/weekly/Fortnightly Buy/Sell data</li>
          <li>Business information (financial health/product quality/ability to survive with competition etc)</li>
          <li>Credit history (Payment history /Intent to pay back loan etc)</li>
          <li>Demographic data (Age/sex/Marital status/Land/Animal/Assets)</li>
          <li>Alternative Data (utility bill payment, social data, mobile phone data, text messages etc)</li>
        </ul><br />
        <p>ORKO provides the following services to its customers</p>
        <ul className="square">
          <li>Ledger Maintenance</li>
          <li>Loan Request </li>
          <li>Management Guidance</li>
          <li>Expert Opinion </li>
        </ul>
      </div>

      <div className="divider-box" id="ledger"></div>
      <div className="about-head"><h1>Ledger Maintenance</h1></div>
      <div className="about">
        <p>
          Customer can keep track of their daily, weekly & monthly ledger using ORKO platform.
          However, customer must register to use this service.
        </p><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>

      <div className="divider-box" id="loan"></div>
      <div className="about-head"><h1>Loan Request</h1></div>
      <div className="about">
        <p>
          CMSME customers can apply for loan using ORKO digital platform.
        </p><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>

      <div className="divider-box" id="guidance" ref={services}></div>
      <div className="about-head"><h1>Management Guidance</h1></div>
      <div className="about" onLoad={()=>setTimeout(()=>scrollToSection(services), 100)}>
        <p>
          Free management guidance to ORKO members through blogs & videos.
        </p><br />
        <ul className="square">
          <li>How to run small business ?</li>
          <li>How to market your products ?</li>
        </ul><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>

      <div className="divider-box" id="expert" ref={expert}></div>
      <div className="about-head"><h1>Expert Opinion</h1></div>
      <div className="about" onLoad={()=>setTimeout(()=>scrollToSection(expert), 1000)}>
        <p>
          ORKO members can book appointment with industry experts to consult issues they are facing
          in running their business in exchange of a fee.
        </p><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>

      {/* <div className="about-foot"></div> */}

      {/* <div className="divider-box" id="vision"></div>
      <div className="vision-head"><h1>Vision &</h1><br /><h1>Values</h1></div>
      <div className="vision">
        <p>
          Orko is a digital platform that provides CMSME customers management
          guidance & financial solution from partner Banks & Financial Institutions
          thereby serving their financial and operational needs.
        </p>
        <div className="left">
          <h3><i className="fa-regular fa-eye"></i> Vision</h3>
          <p>The <em>most preferred organization for the development of progressive Micro
             Enterprises</em> to enhance wealth creation and social well-being of the nation.</p>
        </div>
        <div className="right">
          <h3><i className="fa-solid fa-scale-balanced"></i> Values</h3>
          <p>Our exceptional client service is deeply entwined in a <strong>
            <span>C</span><span>H</span><span>A</span><span>I</span><span>N</span> </strong>
             value system</p>
          <ul>
            <li><i className="fa-regular fa-handshake"></i> <strong><span>C</span>ollaboration</strong> - we work
             together to achieve collective and individual goals.</li>
            <li><i className="fa-solid fa-brain"></i> <strong><span>H</span>olistic perspective</strong> - we seek to
             continuously identify competitive advantage in all of our engagements through an outside-in
             and inside-out approach and convey a holistic perspective to our clients/ engagement partners
             to empower objective decision making based on rigorous data seeding & analysis</li>
            <li><i className="fa-solid fa-hand-point-right"></i> <strong><span>A</span>ccountability</strong> - We
             take responsibility for individual and collective actions</li>
            <li><i className="fa-brands fa-medium"></i> <strong><span>I</span>mpact</strong> - we are inspired
             to make a positive & lasting social impact!!!</li>
            <li><i className="fa-brands fa-pagelines"></i> <strong><span>N</span>urturing relationships</strong> - the way
             we treat each other in achieving our objectives is just as important as what we achieve</li>
          </ul>
        </div>
      </div>
      <div className="vision-foot"></div> */}

      <div style={{display: "none"}} className="divider-box" id="services" ref={services}></div>
      <div style={{display: "none"}} className="services-head"><h1><span>S</span>ervices</h1></div>
      <div style={{display: "none"}} className="services" onLoad={()=>setTimeout(()=>scrollToSection(services), 10)}>
        <img src="/products.jpg" />
        <ul>
          <li><i className="fa-solid fa-person-chalkboard"></i> Expert opinion</li>
          <li><i className="fa-solid fa-magnifying-glass"></i> Market Research</li>
          <li><i className="fa-solid fa-plane-departure"></i>  Product Launching</li>
          <li><i className="fa-solid fa-bullhorn"></i> Brand Promotion</li>
          <li><i className="fa-solid fa-users"></i> Recruitment</li>
          <li><i className="fa-solid fa-graduation-cap"></i> Training</li>
          <li><i className="fa-solid fa-computer"></i> IT Solutions</li>
          <li><i className="fa-solid fa-sack-dollar"></i> Fund Raising</li>
        </ul>
      </div>
      <div style={{display: "none"}} className="services-foot"></div>

      <Footer bangla={bangla} />
    </div>
  );
}

export default AboutPage;
