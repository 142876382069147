import "./Footer.css";

function Footer({ bangla }) {
  return (
    <footer>
      <div className="container">
        {bangla?(<div style={{padding: "0 68px 0 9px", backgroundColor: "transparent",
          height: "70.4px", position: "relative"}}><img src="orko_4.png" alt="Logo" />
          <p style={{color: "#fff", position: "absolute", top: "23px", left: "81px",
          fontSize: "135%", fontWeight: 600}}>অর্কো</p></div>)
        :<img src="orko_logo1.png" alt="Logo" />}
        <hr />
        <div className="footer">
          <p>&copy; 2023 Orko Bangladesh, {bangla?"সর্বস্বত্ব সংরক্ষিত":"All Rights Reserved"}</p>
          <ul>
            <li><a target="_blank" href="https://www.facebook.com/profile.php?id=100089508392790"><i className="fa-brands fa-facebook"></i></a></li>
            <li><a target="_blank" href="https://www.linkedin.com/company/orkobd/"><i className="fa-brands fa-linkedin"></i></a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
